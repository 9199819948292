import React from "react";
import axios from "axios";
import queryString from "query-string";
import Loader from "../loader_black.gif";
import { Typeahead } from "react-bootstrap-typeahead";
import debounce from "lodash/debounce";
import "../Search.css";

class Search extends React.Component {
  constructor() {
    super();

    this.state = {
      query: "",
      facets: {
        categories_exact: "",
        documentstyle_exact: "",
        documenttype_exact: "",
        tag_exact: "",
        status_exact: "",
      },
      results: [],
      resultCount: 0,
      loading: false,
      hasMoreEntries: true,
      message: "",
      currentPageNo: 0,
      typeahead: [],
      typeaheadOrigin: [],
      selected: {},
    };

    this.cancel = "";
    this._typeahead = [];
    this.singleRef = React.createRef();
    this.totalCount = 2;
    this.hostUrl = window.location.origin;
    this.apiEndpoint = this.hostUrl + `/search_endpoint/`;
    this.searchInput = null;

    this.callback = (entries) => {
      const query = this.state.query;
      if (entries[0].isIntersecting && this.state.hasMoreEntries) {
        const currentPageNo = this.state.currentPageNo + 1;
        this.setState(
          { query, loading: true, message: "", currentPageNo },
          () => {
            this.fetchSearchResults(currentPageNo);
          },
        );
      }
    };

    this.observer = {};
  }

  async componentDidMount() {
    this.fetchSearchResults = debounce(this.fetchSearchResults, 300);
    this.fetchSearchOptions(true);
    this.startResultAnimation();
    const stickyState = localStorage.getItem("fhnw-help-state");
    if (this.props.location.search && this.props.location.search.length) {
      const query = queryString.parse(this.props.location.search);
      this.setState({ query: query.nav_search });
      this.fetchSearchResults(1);
    } else if (!this.props.location.search.length && stickyState !== null) {
      let tempStickyState = JSON.parse(stickyState);
      tempStickyState.results = [];
      this.setState(tempStickyState, () => {
        this.fetchSearchResults(1);
      });
    }
    this.searchInput.focus();
  }

  componentDidUpdate() {
    if (this.singleRef.current) {
      this.handleIntersectionObserver();
    }
  }

  fetchSearchOptions = (init) => {
    const searchUrl = this.apiEndpoint + `?q=*&page=1`;

    axios
      .get(searchUrl, {
        cancelToken: this.cancel.token,
      })
      .then((res) => {
        this.mapSearchOptions(res, init);
      })
      .catch((error) => {
        console.error("Failed to fetch the data. Please check network!");
      });
  };

  mapSearchOptions = (res, init) => {
    const options = {
      service: [],
      mode: [],
      type: [],
      status: [],
      keyword: [],
    };

    const fieldMap = {
      categories: "service",
      documentstyle: "mode",
      documenttype: "type",
      status: "status",
      tag: "keyword",
    };

    for (const [key, value] of Object.entries(fieldMap)) {
      res.data.facets.fields[key].map((val) => {
        if (val[1] === 0) return false;
        options[value].push({ id: val[0], name: val[2] });
      });
    }

    const typeahead = {
      typeahead: [
        {
          id: 1,
          name: "categories_exact",
          options: options.service,
          placeholder: "IT Service",
        },
        {
          id: 2,
          name: "documentstyle_exact",
          options: options.mode,
          placeholder: "Dokumentationsart",
        },
        {
          id: 3,
          name: "documenttype_exact",
          options: options.type,
          placeholder: "Dokumentationstyp",
        },
        {
          id: 4,
          name: "tag_exact",
          options: options.keyword,
          placeholder: "Stichwörter zu IT Service",
        },
        {
          id: 5,
          name: "status_exact",
          options: options.status,
          placeholder: "Status",
        },
      ],
    };

    this.setState(() => {
      return typeahead;
    });

    if (init) this.setState({ typeaheadOrigin: typeahead.typeahead });
  };

  handleIntersectionObserver = () => {
    if (this.state.loading) return;
    if (this.observer.current) {
      this.observer.current.disconnect();
    }
    this.observer.current = new IntersectionObserver(this.callback);
    this.observer.current.observe(this.singleRef.current);
  };

  startResultAnimation() {
    var textWrapper = window.document.querySelector("#anime");
    if (textWrapper && window.anime) {
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>",
      );
      window.anime.timeline({ loop: false }).add({
        targets: "#anime .letter",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 500,
        delay: (el, i) => 40 * (i + 1),
      });
    }
  }

  /**
   * Fetch the search results and update the state with the result.
   * Also cancels the previous query before making the new one.
   *
   * @param {int} updatedPageNo Updated Page No.
   *
   */
  fetchSearchResults = (updatedPageNo = "") => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : "";
    const { facets, query } = this.state;
    let facetStr = "";

    for (var prop in facets) {
      facetStr += facets[prop];
    }

    const q = query === "" ? "*" : query;

    const searchUrl = this.apiEndpoint + `?q=${q}${facetStr}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios
      .get(searchUrl, {
        cancelToken: this.cancel.token,
      })
      .then((res) => {
        this.mapSearchOptions(res);

        let msgIsAuthenticated = "";
        const isAuthenticated = res.data.user_logged_in;

        isAuthenticated === true
          ? (msgIsAuthenticated = ".")
          : (msgIsAuthenticated =
              ". Bitte überprüfen sie, ob sie eingeloggt sind.");

        const resultNotFoundMsg = !res.data.results.length
          ? `Keine Artikel gefunden. Versuchen Sie es bitte mit einem anderen Begriff${msgIsAuthenticated}`
          : "";

        const filteredEntries = this.filterById(res.data.results, this.state.results);
        
        const results = [
          ...new Set([...this.state.results, ...filteredEntries]),
        ]

        const totalEntries = res.data.result_count;
        const hasMoreEntries = totalEntries > results.length ? true : false;

        this.setState({
          results: results,
          resultCount: res.data.result_count,
          message: resultNotFoundMsg,
          currentPageNo: updatedPageNo,
          loading: false,
          hasMoreEntries,
        });
        if (!hasMoreEntries) this.disconnectObserver();
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
          });
        }
        console.error("Failed to fetch the data. Please check network");
        this.disconnectObserver();
      });
  };

  /**
   * Filters dublicated entries
   *
   * @param {Array} arr1
   * @param {Array} arr2
   */
  filterById = (arr1, arr2) => {
     let res = [];
     res = arr1.filter(el => {
        return !arr2.find(element => {
           return element.id === el.id;
        });
     });
     return res;
  }

  disconnectObserver() {
    if (this.observer.current) {
      this.observer.current.disconnect();
      this.singleRef.current = null;
    }
  }

  /**
   * Get typeahead params, create query, update the state with the query
   * and fetch the results. Also checks for empty facets in state.
   *
   * @event SyntheticEvent#onChange
   * @param {Object} facet
   */
  handleOnTypeaheadChange = (event, facet) => {
    let partQ = "&selected_facets=" + facet.name + ":";
    let query = "";
    let elements = [];

    const [{ options }] = this.state.typeaheadOrigin.filter(
      (option) => option.name === facet.name,
    );

    const filteredOptions = [];

    event.map((val) => {
      elements.push(val.id);
      options.forEach((option) =>
        option.id === val.id ? filteredOptions.push(option) : null,
      );
    });

    if (event.length) query = partQ.concat(elements.join(","));

    const facets = {
      ...this.state.facets,
      [facet.name]: query,
    };

    const selected = {
      ...this.state.selected,
      [facet.name]: filteredOptions,
    };

    const checkEmpty = this.checkEmpty(facets);

    if (!this.state.query && checkEmpty) {
      this.setState({
        facets,
        results: [],
        message: "",
        selected: {},
      });
    } else {
      this.setState({ facets, loading: true, message: "", selected, results: [] }, () => {
        this.fetchSearchResults(1);
      });
    }
    this.fetchSearchOptions();
  };

  /**
   * Get keyword and update the state with the keyword, then fetch
   * results according to the sent requests.
   *
   * @event SyntheticEvent#onChange
   */
  handleOnInputChange = (event) => {
    const query = event.target.value;
    const checkEmpty = this.checkEmpty(this.state.facets);

    if (!query && checkEmpty) {
      this.setState({
        query,
        results: [],
        message: "",
      });
    } else {
      this.setState({ query, loading: true, message: "", results: [] }, () => {
        this.fetchSearchResults(1);
      });
    }
  };

  /**
   * Reset search values
   *
   */
  resetSearch = () => {
    const facets = {
      categories_exact: "",
      documentstyle_exact: "",
      documenttype_exact: "",
      tag_exact: "",
      status_exact: "",
    };
    const query = "";

    this._typeahead.map((f) => {
      f.clear();
    });
    this._typeahead = [];

    this.setState({
      query,
      facets,
      results: {},
      message: "",
      selected: {},
    });
    this.removePersistedState();
    this.fetchSearchOptions();
    window.history.pushState({}, document.title, "/");
  };

  /**
   * Check if object properties are empty
   *
   * @param {Object} obj
   */
  checkEmpty = (obj) => {
    for (let key in obj) {
      //if the value is 'object'
      if (obj[key] instanceof Object === true) {
        if (this.checkEmpty(obj[key]) === false) return false;
      }
      //if value is string/number
      else {
        //if array or string have length is not 0.
        if (obj[key].length !== 0) return false;
      }
    }
    return true;
  };

  persistState = () => {
    localStorage.setItem("fhnw-help-state", JSON.stringify(this.state));
  };

  removePersistedState = () => {
    localStorage.removeItem("fhnw-help-state");
  };

  renderSearchResults = () => {
    const { results, resultCount } = this.state;
    const renderRef = (results, index) => {
      if (results.length === index + 1) {
        return this.singleRef;
      }
      return false;
    };

    if (Object.keys(results).length && results.length) {
      return (
        <div className="list-group">
          <div className="ml-2 mt-1 mb-1">
            <p id="anime" className="h6 css-fade">
              {resultCount} Einträge gefunden
            </p>
          </div>
          {results.map((result, index) => {
            return (
              <a
                key={result.id}
                href={result.url}
                className="list-group-item list-group-item-action flex-column align-items-start css-fade"
                onClick={this.persistState}
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 ref={renderRef(results, index)} className="mb-1">
                    {result.title}
                  </h5>
                  <small>{result.type}</small>
                </div>
                <p className="mb-1">{result.description}</p>
              </a>
            );
          })}
        </div>
      );
    }
  };

  render() {
    const { query, loading, message } = this.state;

    return (
      <div id="help-search-results" className="col-12">
        {/* Search Input*/}
        <div className="input-group input-group-lg">
          <input
            className="form-control"
            aria-label="Large"
            aria-describedby="inputGroup-sizing-sm"
            type="text"
            name="query"
            value={query}
            id="search-input"
            placeholder="Suche ..."
            onChange={this.handleOnInputChange}
            autoComplete="off"
            ref={(input) => {
              this.searchInput = input;
            }}
          />
          <span className="input-group-btn">
            <button
              id="search_btn"
              className="btn btn-primary"
              type="button"
              onClick={this.resetSearch}
              data-toggle="tooltip"
              data-title="Angaben löschen"
            >
              <i className="fas fa-eraser"></i>
            </button>
          </span>
        </div>

        <div className="input-group input-group-lg mt-2">
          {/* Typeahead Select */}
          {this.state.typeahead.map((facet) => (
            <Typeahead
              key={facet.id}
              id={facet.id}
              labelKey="name"
              multiple={true}
              selected={this.state.selected[facet.name]}
              options={facet.options}
              placeholder={facet.placeholder}
              ref={(ref) => (ref !== null ? this._typeahead.push(ref) : null)}
              onChange={(event) => this.handleOnTypeaheadChange(event, facet)}
            />
          ))}
        </div>

        {/*	Error Message*/}
        {message && (
          <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-triangle mr-1"></i> {message}
          </div>
        )}

        {/*	Loader*/}
        <img
          src={Loader}
          className={`search-loading ${loading ? "show" : "hide"}`}
          alt="loader"
        />

        {/*	Result*/}
        {this.renderSearchResults()}
      </div>
    );
  }
}

export default Search;
